import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins'
import './registerServiceWorker'
import './assets/scss/main.scss'
import store from './store/index.js'
import Notifications from 'vue-notification'
import VueResource from 'vue-resource'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueResource);
Vue.use(Notifications)


Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GTAG,
    params: {
      send_page_view: true
    }
  },
  includes: [
    { id: process.env.VUE_APP_GTAG_2 }
  ]
});


new Vue({
  router,
  store,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  render: h => h(App)
}).$mount('#app')
