import Vue from 'vue'
import Router from 'vue-router'

// Home component
import HomePage from './views/HomePage'
/** Tempalte */
import BackendTemplate from './views/template/backend.vue'

/** Backend */
import Dashboard from './views/dashboard'
import News from './views/news'
import NewsDetail from './views/news/NewsDetails'

import CatePodcast from './views/Category/podcast.vue'
import CateMovie from './views/Category/movie.vue'
import CateClip from './views/Category/clip.vue'

import PodcastPlayer from './views/Podcast'

import LoginPage from './views/Login'
import RadioPage from './views/Radio/index.vue'
import RadioHistory from './views/Radio/history/index.vue'
import RadioHistoryList from './views/Radio/history/list.vue'

import ThinkStudio from './views/ThinkStudio/index.vue'
import ThinkStudioList from './views/ThinkStudio/list.vue'


import HotIssue from './views/HotIssue'
import HotIssueList from './views/HotIssue/list.vue'

import Blog from './views/Blog'
import Partner from './views/Partner'
import Shopping from './views/Product/list.vue'

// Layout components
import AppHeader from '@/components/Layouts/AppHeader'
import AppFooter from '@/components/Layouts/AppFooter'

// Product
import ProductDetails from './views/Product/ProductDetails'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/auth',
      component: BackendTemplate,
      children: [
        {
          path: 'dashboard',
          component: Dashboard
        }, {
          path: 'news',
          component: News
        }
      ]
    },
    {
      path: '/',
      name: 'homepage',
      components: {
        header: AppHeader,
        default: HomePage,
        navigator: AppFooter
      }
    }, {
      path: '/login',
      name: 'login',
      components: {
        header: AppHeader,
        default: LoginPage,
        navigator: AppFooter
      }
    }, {
      path: '/podcast',
      components: {
        header: AppHeader,
        default: CatePodcast,
        navigator: AppFooter
      }
    },{
      path: '/shopping',
      components: {
        header: AppHeader,
        default: Shopping,
        navigator: AppFooter
      }
    }, {
      path: '/podcast/:cid',
      components: {
        header: AppHeader,
        default: CatePodcast,
        navigator: AppFooter
      }
    },
    {
      path: '/media/:id',
      name: 'podcast',
      components: {
        header: AppHeader,
        default: PodcastPlayer,
        navigator: AppFooter
      }
    },
    {
      path: '/product/:id',
      name: 'product_details',
      components: {
        header: AppHeader,
        default: ProductDetails,
        navigator: AppFooter
      }
    },{
      path: '/radio',
      name: 'radio',
      components: {
        header: AppHeader,
        default: RadioPage,
        navigator: AppFooter
      }
    }, {
      path: '/radio/history',
      name: 'radio_history',
      components: {
        header: AppHeader,
        default: RadioHistory,
        navigator: AppFooter
      }
    },
    {
      path: '/radio/history/:id',
      name: 'radio_history_list',
      components: {
        header: AppHeader,
        default: RadioHistoryList,
        navigator: AppFooter
      }
    },

    
    {
      path: '/news_details',
      name: 'news_details',
      components: {
        header: AppHeader,
        default: NewsDetail,
        navigator: AppFooter
      }
    }, 
    {
      path: '/think_studio',
      name: 'think_studio_list',
      components: {
        header: AppHeader,
        default: ThinkStudioList,
        navigator: AppFooter
      }
    }, {
      path: '/think_studio/:id',
      name: 'think_studio',
      components: {
        header: AppHeader,
        default: ThinkStudio,
        navigator: AppFooter
      }
    }, {
      path: '/hot_issue',
      name: 'hot_issue_list',
      components: {
        header: AppHeader,
        default: HotIssueList,
        navigator: AppFooter
      }
    }, {
      path: '/hot_issue/:id',
      name: 'hot_issue',
      components: {
        header: AppHeader,
        default: HotIssue,
        navigator: AppFooter
      }
    }, {
      path: '/blog/:id',
      name: 'blog_view',
      components: {
        header: AppHeader,
        default: Blog,
        navigator: AppFooter
      }
    }, {
      path: '/partner',
      name: 'partner',
      components: {
        header: AppHeader,
        default: Partner,
        navigator: AppFooter
      }
    }
  ],
  // Set scroll top 0
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
